/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Modal, ButtonToolbar, Button } from "rsuite";
import { location, location_1, location_2 } from "../../assets";
import { Popover, Whisper } from 'rsuite';

const LocationModal = () => {
  const [open, setOpen] = React.useState(false);
  const [size, setSize] = React.useState();
  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const copyHandler = () => {
    var txt = document.getElementById("txt")?.innerHTML;
    document.getElementById("txt")?.select();
    navigator.clipboard.writeText(txt);
  };

  return (
    <div className="font-[Inter]">
      <ButtonToolbar>
        <Button size="lg" onClick={() => handleOpen("xs")}>
          See Details
        </Button>
      </ButtonToolbar>
      <Modal size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <div className="font-semibold">Location</div>
        </Modal.Header>
        <Modal.Body>
          {/* <Placeholder.Paragraph /> */}
          <div>
            <div>
              <div>
                Please go to the web browser.{" "}
                <div className="border p-2 items-center text-gray-800 bg-slate-200 mt-4 px-4 flex gap-4 rounded-lg w-fit">
                  <div id="txt">https://fcmu.wbpcb.gov.in </div>
                  <Whisper
                    placement="top"
                    trigger="click"
                    speaker={
                      <Popover arrow={false}>
                        copied !!
                      </Popover>
                    }
                  >
                    <Button><i class="fa-regular fa-copy" onClick={copyHandler}></i></Button>
                  </Whisper>
                </div>
              </div>
              <br />
              1. Please give the location permission.
            </div>
            <div className="rounded-xl overflow-hidden mt-3">
              <img src={location} alt="" />
            </div>
            <div className="mt-3">
              2. Click here & then you can see this popup.
            </div>
            <div className="border rounded-2xl overflow-hidden mt-3">
              <img src={location_1} alt="" />
            </div>
            <div className="mt-3">3. Then turn on loaction</div>
            <div className="border rounded-2xl overflow-hidden mt-3">
              <img src={location_2} alt="" />
            </div>
            <div className="mt-3">4. After that, please refresh the app.</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mt-3">
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LocationModal;

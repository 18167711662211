import React from 'react';
import './App.css';
import { user } from './Data/auth';
import Navigation from './Pages/Navigation';
import OtpModal from './components/OtpModal/OtpModal';

function App() {

  return (
    user ? <Navigation /> :
      <OtpModal />
  );
}

export default App;

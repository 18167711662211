import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { lazy, Suspense } from 'react';
import Home from './Home';
import PreviousDetails from '../components/PreviousFormDetails/PreviousDetails';
import Form from '../components/Form/Form'
import Preview from './Preview';
import BiodiversityFrom from '../components/Form/BiodiversityFrom'


export default function Navigation() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/preview" element={<Preview />} /> */}
                    <Route path="/add_new_form" element={<BiodiversityFrom />} />
                    {/* <Route path="/update_form" element={<Form />} />
                    <Route path="/update_form_preview" element={<Preview />} />
                    <Route path="/previous_details" element={<PreviousDetails />} /> */}
                </Routes>
            </Suspense>
        </Router>
    )
}

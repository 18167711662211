export const _questions = [
    { label: "Name", code: "name" },
    { label: "Permanent Address", code: "permanent_address" },
    { label: "Address of contact person/agent (if applicant resides outside India)", code: "agent_address" },  //(if applicant resides outside India)
    { label: "Profile of the organisation ", code: "profile_organisation" },
    { label: "Attach relevant documents for authentication", code: "auth_document", type: "upload" },
    { label: "Nature of busness", code: "busness_nature" },
    { label: "Turnover of the organisation", code: "org_turnover", },
    { label: "Identification (scientific name) of biological resources and its traditional use:", code: "identification" },
    { label: "Geographical location of proposed place of collection:", code: "geo_location", },
    { label: "Description/ nature of traditional knowledge (oral/ documented):", code: "des_traditional" },
    { label: "Any individual/ community holding the traditional knowledge:", code: "holding_traditional" },
    { label: "Quantity of biological resources to be collected (give the schedule):", code: "quantity_biological" },
    { label: "Time span in which the biological resources is proposed to be collected:", code: "res_collected" },
    { label: "Name of person authorised by the company for making the selection:", code: "auth_person" },
    { label: "Contact number of person authorised by the company for making the selection:", code: "contact_auth_person" },
    { label: "The purpose for which the access is requested including the type and extent of research and the commercial use being derived and expected to be derived from it:", code: "purpose_auth" },
    { label: "Whether any collection of the resources endangers any component of biological diversity and the risks which may arise from the access:", code: "risk_can_arise" },
    { label: "Details of any national institution which will participate in the Research and Development activities:", code: "institution_name" },
    { label: "Economic and other benefits including those arising out of any IPR, patent obtained out of accessed biological resources and knowledge that are intended or may accrue to the applicant or to the country that he/she belongs:", code: "individual_benefits" },
    { label: "The biotechnological, scientific, social or any other benefits obtained out of accessed biological resources and knowledge that are intended or may accrue to the applicant or to the country that he/she belongs:", code: "ohers_benefits" },
    {
        label: "Declaration: I/we hereby declare that the collection of proposed biological resources shall not :-", options: [{
            option: "Adversely affect the sustainability of the resources"
        },
        { option: "Entail any environmental impact" },
        { option: "Pose any risk to ecosystems" },
        { option: "Adversely affect the local communities" },
        { option: "Adversely affect the local communities" }
        ], code: "options", type: "option"
    },
    { label1: "I/we further declare the information provided in this application form is true and are correct and I/we shall be responsible for any incorrect/wrong information herein", label: "Place:", code: "place" },
    { label: "Date:", code: "date" },
    // {
    //   label: "Signature", code: "signature", type: "upload"
    // },
    // { label: "Name:", code: "p_name" }
]

export const QuestionPage1 = [
    { label: "Name", code: "name", id: 1 },
    { label: "Permanent Address", code: "permanent_address", id: 2 },
    { label: "Address of contact person/agent (if applicant resides outside India)", code: "agent_address", id: 3 },  //(if applicant resides outside India)
    { label: "Profile of the organisation ", code: "profile_organisation", id: 4 },
    { label: "Attach relevant documents for authentication", code: "auth_document", type: "upload", id: 5 },
    { label: "Nature of busness", code: "busness_nature", id: 6 },
    { label: "Turnover of the organisation", code: "org_turnover", id: 7 },
]

export const QuestionPage2 = [
    { label: "Identification (scientific name) of biological resources and its traditional use:", code: "identification", id: 8 },
    { label: "Geographical location of proposed place of collection:", code: "geo_location", id: 9 },
    { label: "Description/ nature of traditional knowledge (oral/ documented):", code: "des_traditional", id: 10 },
    { label: "Any individual/ community holding the traditional knowledge:", code: "holding_traditional", id: 11 },
    { label: "Quantity of biological resources to be collected (give the schedule):", code: "quantity_biological", id: 12 },
    { label: "Time span in which the biological resources is proposed to be collected:", code: "res_collected", id: 13 },
]

export const QuestionPage3 = [
    { label: "Name of person authorised by the company for making the selection:", code: "auth_person", id: 14 },
    { label: "Contact number of person authorised by the company for making the selection:", code: "contact_auth_person", id: 15 },
    { label: "The purpose for which the access is requested including the type and extent of research and the commercial use being derived and expected to be derived from it:", code: "purpose_auth", inputType: "textarea", id: 16 },
    { label: "Whether any collection of the resources endangers any component of biological diversity and the risks which may arise from the access:", code: "risk_can_arise", inputType: "textarea", id: 17 },
    { label: "Details of any national institution which will participate in the Research and Development activities:", code: "institution_name", id: 18 },

]

export const QuestionPage4 = [
    { label: "Primary destination of accessed resource and identity of the location where the Research and Development will be carried out:", code: "Primary destination of accessed resource and identity of the location where the Research and Development will be carried out:", inputType: "textarea", id: 19 },

    { label: "Economic and other benefits including those arising out of any IPR, patent obtained out of accessed biological resources and knowledge that are intended or may accrue to the applicant or to the country that he/she belongs:", code: "individual_benefits", inputType: "textarea", id: 20 },
    { label: "The biotechnological, scientific, social or any other benefits obtained out of accessed biological resources and knowledge that are intended or may accrue to the applicant or to the country that he/she belongs:", code: "ohers_benefits", inputType: "textarea", id: 21 },

]

export const QuestionPage5 = [
    { label: "Estimation of benefits that would flow to India/ communities arising out of the use of accessed biological resources and traditional knowledge:", inputType: "textarea", code: "Estimationknowledge", id: 22 },

    { label: 'Proposed mechanism and arrangements for benefit sharing:', code: "Proposed_mechanism", inputType: "textarea", id: 23 },
    { label: 'Any other information considered relevant:', code: "information_considered", inputType: "textarea", id: 24 },

]



import React, { useState } from 'react'
import InputBox from '../InputBox'
import { QuestionPage1, QuestionPage2, QuestionPage3, QuestionPage4, QuestionPage5 } from "./QuestionsDatabase"
import ArrowRight from "../../assets/arrowRight.png"
import UploadIcon from "../../assets/UploadIcon.png"
import { useNavigate } from "react-router-dom";
import "./Style.css"


const BiodiversityFrom = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();


  const _oSubmitHandler = () => {
    alert("Your response was submitted successfully.")
    navigate("/");
  }
  return (
    <div className='px-6 py-4 bg-[#d9eef1] h-fit'>
      {/* Page 1 */}
      <div className=" bg-[#d9eef1]">

        {currentPage === 1 &&
          <><h1 className='text-xl font-medium '>Full Particulars of the applicant</h1>
            <div className='h-[2px] bg-[#7096b9]' />
          </>}
        {currentPage === 2 &&
          <><h1 className='text-xl font-medium '>Details and specific information about nature of access sought and biological material</h1>
            <div className='h-[2px] bg-[#7096b9]' />
          </>}
        {currentPage === 1 && QuestionPage1.map((item, index) => <InputBox item={item} id={item.id} key={item.label + index} />)}
        {currentPage === 2 && QuestionPage2.map((item, index) => <InputBox item={item} id={item.id} key={item.label + index} />)}
        {currentPage === 3 && QuestionPage3.map((item, index) => <InputBox item={item} id={item.id} key={item.label + index} />)}
        {currentPage === 4 && QuestionPage4.map((item, index) => <InputBox item={item} id={item.id} key={item.label + index} />)}
        {currentPage === 5 && QuestionPage5.map((item, index) => <InputBox item={item} id={item.id} key={item.label + index} />)}
        {currentPage === 6 && <>
          <h1 className='text-xl font-medium '>Declaration: I/we hereby declare that the collection of proposed biological resources shall not :-</h1>
          <div className='h-[2px] bg-[#7096b9]' />


          <div className='py-4'>
            <div className='flex'>
              <input type='checkbox' className='mr-4' />
              <h1 className='py-1 text-[15px] text-clip font-semibold'>Adversely affect the sustainability of the resources</h1>
            </div>
            <div className='flex'>
              <input type='checkbox' className='mr-4' />
              <h1 className='py-1 text-[15px] text-clip font-semibold'>Entail any environmental impact</h1>
            </div>
            <div className='flex'>
              <input type='checkbox' className='mr-4' />
              <h1 className='py-1 text-[15px] text-clip font-semibold'>Pose any risk to ecosystems</h1>
            </div>
            <div className='flex'>
              <input type='checkbox' className='mr-4' />
              <h1 className='py-1 text-[15px] text-clip font-semibold'>Adversely affect the local communities</h1>
            </div>
            <div className='flex'>
              <input type='checkbox' className='mr-4' />
              <h1 className='py-1 text-[15px] text-clip font-semibold'>Destroy any other biological resources</h1>
            </div>

            <h1 className='py-1 text-[18px] text-clip font-semibold '>I/we further declare the information provided in this application form is true and are correct and I/we shall be responsible for any incorrect/wrong information herein</h1>
            <div className=''>

              <h1 className='py-1 text-[15px] text-clip font-semibold'>Place:</h1>
              <input className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2 w-[90vw]' />
            </div>
            <div className='pt-2'>

              <h1 className='py-1 text-[15px] text-clip font-semibold'>Date:</h1>
              <input className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2 w-[90vw]' />
            </div>
            <div className='pt-2'>

              <h1 className='py-1 text-[15px] text-clip font-semibold'>Signature:</h1>
              <div className=" border-[2px] border-[#d5d5d5] h-12 bg-white rounded-lg flex  justify-between items-center px-4 text-[#4c9daf]" >
                Upload file
                <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
              </div>
              <div className='pt-2'>

                <h1 className='py-1 text-[15px] text-clip font-semibold'>Name:</h1>
                <input className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2 w-[90vw]' />
              </div>
            </div>

          </div>
        </>}



        <div className=' flex items-center justify-end mt-4 '>
          <div className='flex w-[6.5rem] bg-white py-2 px-4 rounded-lg select-none' onClick={() => {
            currentPage !== 6 ? setCurrentPage(currentPage + 1) : _oSubmitHandler();
          }}>
            <h1 className=' text-lg select-none font-semibold mr-2'>{currentPage === 6 ? "Submit" : "Next"}</h1>
            {currentPage !== 6 && <img src={ArrowRight} alt="ArrowRight" className="h-6 w-6 select-none" />}
          </div>
        </div>


      </div>
    </div>
  )
}

export default BiodiversityFrom



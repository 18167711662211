import React, { useState, useRef } from "react";
import UploadIcon from "../assets/UploadIcon.png"

const InputBox = ({ item, id }) => {
    const [updateIndex, setUpdateIndex] = useState(0);
    const [isDisable, setIsDisable] = useState(false);
    const [formValues, setFormValues] = useState();


    const fileInputRef = useRef(null);
    const handleImageChange = (event) => {
        const file = event.target.files?.[0];
        setIsDisable(false)
        setFormValues((prevState) => ({
            ...prevState,
            "auth_document": file,
        }));
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const _oSubmitHandler = () => {
        alert("Your response was submitted successfully.")
        // navigate("/");
    }
    return (
        <div className='py-1'>
            <div className=' flex'>
                <h1 className='py-[2px] text-lg mr-1'>{id}. </h1><h1 className='py-1 text-[15px] text-clip font-semibold'>{item?.label}</h1>
            </div>


            {item?.type === 'upload' ? <>
                <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="mx-4 px-4 text-md text-white "
                    style={{ display: "none" }}
                    ref={fileInputRef}
                />
                <div className=" border-[2px] border-[#d5d5d5] h-12 bg-white rounded-lg mt-4 flex  justify-between items-center px-4 text-[#4c9daf]" onClick={handleButtonClick}>
                    Upload file
                    <img src={UploadIcon} alt="UploadIcon" className="h-8 w-8" />
                </div></> : item?.type === undefined && item?.inputType === "textarea" ? <textarea className=' bg-white py-1 rounded-md h-28 border-[2px] border-[#d5d5d5] outline-none px-2 w-[90vw]' /> : <input className=' bg-white  rounded-md h-12 border-[2px] border-[#d5d5d5] outline-none px-2 w-[90vw]'  ></input>}
        </div>
    )
}

export default InputBox
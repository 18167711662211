import React, { } from 'react'
import OtpModal from "../components/OtpModal/OtpModal";
import Popup from '../components/ButtonPopup/Popup';
import IndexPage from './IndexPage';
import { user } from '../Data/auth';
import LocationModal from '../components/LocationModal/LocationModal';


export default function Home() {

    return (
        <div className="grid sm:grid-cols-2 font-[Inter] h-screen relative bg-white">
            {/* <InstallPWA /> */}
            {user && <div className='absolute right-5 top-10 '>
                <Popup />
            </div>}
            {!user ? <div className="flex justify-center items-center border rounded-2xl">
                <OtpModal />
            </div> :
                <div>
                    <IndexPage />
                </div>
            }
        </div>
    )
}
